.App {
  font-family: "Itim";
}
#card {
  display: none;
}
#icon {
  height: 50px;
  width: 50px;
}
